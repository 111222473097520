import {useEffect, useState} from "react";

export const Shop = () => {

    const [items, setItems] = useState([]);

    useEffect(() => {
        fetch("https://strapi.waywardcookies.com/api/shop-items?populate=*")
            .then(response => response.json())
            .then(resp => setItems(resp.data))
            .catch(error => console.error("Error fetching data:", error));
        document.title = "Wayward Cookies : Shop & Special Items";
    }, []);

    return (
        <div className={"container py-5 d-flex align-items-center justify-content-center"} style={{minHeight: "400px"}}>
            <div className={"row justify-content-center"}>
                {items ?
                    items.map((item) => (
                        <div className={"col col-med-4 col-sm-6 col-lg-3 p-4"} key={item.Name.replaceAll(" ", "")}>
                            <div className="card mb-5">
                                <img
                                    src={`https://strapi.waywardcookies.com${item.ItemImage[0].formats.small?.url || item.ItemImage[0].formats.thumbnail.url}`}
                                    className="card-img-top"
                                    alt={item.ItemImage.alternativeText}/>
                                <div className="card-body p-4">
                                    <p className="card-text">
                                        <strong>{item.Name}</strong>
                                    </p>
                                    <p className="card-text pb-2">
                                        {item.Description}
                                    </p>
                                    <div className={"d-flex"}>
                                        <div>
                                            <a className="btn btn-primary p-2" href={item.BuyURL}
                                               target={"_blank"}
                                               rel={"noreferrer noopener"} role="button">Buy Now
                                            </a>
                                        </div>
                                        <div className={"ms-auto fs-5"}>${item.Price.toFixed(2)}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                    :
                    <div className={"col"}>
                        Check back later for new stock.
                    </div>
                }
            </div>
        </div>
    )
}